// @flow
import * as React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import { Carousel } from "antd";
import { handleToPortal } from "@/utils/sys";
import { useRouter } from "next/router";
import mbanner1 from "@images/home/mbanner1.png";
import mbanner2 from "@images/home/mbanner2.png";
import mbanner3 from "@images/home/mbanner3.png";
import Image from "next/image";
type Props = {};
const Index = (props: Props) => {
    const { t, i18n } = useTranslation("home");
    const router = useRouter();
    return (
        <div className={`${styles.banner}`}>
            <Carousel dots={{ className: styles["custom-dot"] }}>
                <div className={styles.container}>
                    <Image src={mbanner1} alt=""></Image>
                </div>
                <div className={styles.container}>
                    <Image src={mbanner2} alt=""></Image>
                </div>
                <div className={styles.container}>
                    <Image src={mbanner3} alt=""></Image>
                </div>
            </Carousel>
            {/* <div className={`${styles.container} flex items-center justify-center`}>
        <div className={`${styles.left}`}>
          The best TikTok analytics platform
        </div>
        <div className={styles.pic}>
          <Image lazyBoundary="10px" src={image0} alt=""></Image>
        </div>
      </div> */}
        </div>
    );
};

export default Index;
